<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" multi-selection="true" url="elevators/page"
      @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          所属单位
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          所属租户
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('elevator.elevatorType', '梯种')">
          <el-select v-model="search.productTypeId" :style="{ width: $l('250px', '150px') }"
            :placeholder="$t('elevator.elevatorType')" clearable>
            <el-option v-for="item in productTypeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('elevator.onlineStatus', '在线状态')">
          <el-select v-model="search.onlineStatus" :placeholder="$t('elevator.onlineStatus')"
            :style="{ width: $l('150px', '100px') }" clearable>
            <el-option v-for="item in onlineStatusOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('elevator.joinStatus', '入网状态')">
          <el-select v-model="search.inNetState" :placeholder="$t('elevator.joinStatus')"
            :style="{ width: $l('150px', '100px') }" clearable>
            <el-option v-for="item in inNetStateOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search v-if="$i18n.isCn" :label="$l('elevator.accessPlatform', '对接平台')">
          <el-select v-model="search.integrationKey" :placeholder="$t('elevator.accessPlatform')"
            :style="{ width: $l('160px', '150px') }" clearable>
            <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
              :value="item.integrationKey"></el-option>
          </el-select>
        </vm-search>
        <vm-search label="所属单位">
          <el-input v-model.trim="search.orgName" clearable></el-input>
        </vm-search>
        <vm-search label="所属租户">
          <el-input v-model.trim="search.tenantName" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('elevator.no')" width="120" align="center">
        <template v-slot="scope">
          <router-link :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="tenantName" label="租户" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orgName" :label="$l('elevator.belongCompany', '所属单位')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="regNo" :label="$l('elevator.registerCode', '注册代码')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" :label="$t('elevator.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center" width="120"></el-table-column>
      <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="maintComName" :label="$t('elevator.maintenanced')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createComName" label="制造企业" show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevatorPropertyComName" label="物业公司" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" width="100" align="center">
        <template v-slot="scope">
          <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
            <span>{{ item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" : ",")
            }}</span>
          </span>
        </template>
      </el-table-column>
    </vm-table>
    <set-company :multi-select="multiSelect" action-type="elevator" ref="setCompany"
      @save-success="getList(-1)"></set-company>
    <set-tenant :multi-select="multiSelect" action-type="elevator" ref="setTenant"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
import SetTenant from "@/views/tenant/SetTenant";
import SetCompany from "@/views/tenant/SetCompany";

const moduleName = "elevators";
const moduleName_productType = "elevatorProductTypes";

export default {
  components: { SetCompany, SetTenant },
  data() {
    return {
      search: {
        filter: "",
        productTypeId: "",
        integrationKey: "",
        maintComId: "",
        quantityOrganId: "",
        createComId: "",
        installComId: "",
        remakeComId: "",
        useUnitId: "",
        onlineStatus: "",
        inNetState: "",
        hasActivatedParts: "",
        districtId: "",
        orgName: "",
        tenantName: "",
      },
      loading: false,
      multipleDisabled: false,
      multiSelect: [],
      productTypeOptions: [],
      inNetStateOptions: [
        { value: "false", label: this.$t("elevator.notJoined") },
        { value: "true", label: this.$t("elevator.joined") },
      ],
      onlineStatusOptions: [
        { value: 0, label: this.$t("device.offline") },
        { value: 1, label: this.$t("device.online") },
      ],
      integrationList: [],
    };
  },
  mounted() {
    this.getList(1);
    this.getProductTypeOptions();
    this.getIntegrationList();
  },
  methods: {
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },
    getProductTypeOptions() {
      this.$api.getList(moduleName_productType).then(response => {
        for (let productType of response.data) {
          let item = "";
          if (this.$i18n.isCn) {
            item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
          } else {
            item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn };
          }
          this.productTypeOptions.push(item);
        }
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    handleSelectionChange(val) {
      this.multiSelect = val;
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.vm-main .vm-main-page .vm-content .vm-page {
  padding: 17px 22px 17px 0;
}
</style>
